<script setup>
import { useAnalyticsApi } from "~/composables/useAnalyticsApi";
import { useAnalyticsStore } from "~/store/useAnalyticsStore";
import { storeToRefs } from "pinia";

const { linkId } = defineProps({
  linkId: {
    type: Number,
    required: false,
  },
});

const route = useRoute();
const collectionId = route.params.id;

const { data, loading, fetchTrafficSourcesData } = useAnalyticsApi();
const { selectedDateRange, filters, filtersValues } = storeToRefs(useAnalyticsStore());

const fetchData = () => fetchTrafficSourcesData({ collectionId, linkId });

onMounted(fetchData);

watch([selectedDateRange, filters, filtersValues], fetchData);
</script>

<template>
  <div class="h-full">
    <div v-if="loading" class="loading-overlay rounded-xl">
      <LoadersRingLoader />
    </div>

    <div class="grid-container grid-auto-rows remove-scroll">
      <div class="grid-container-col">
        <div class="flex-container">
          <ExpandableTableTrafficSources
            :data="data?.referrals"
            :totalClicks="data?.total_clicks"
            :header="'Domains'"
            :colKey="'domains'"
          />
        </div>
      </div>
      <div class="grid-container-col">
        <ExpandableTableTrafficSources
          :data="data?.referrals"
          :totalClicks="data?.total_clicks"
          :header="'Referrals'"
          :colKey="'referrals'"
        />
      </div>
      <div class="grid-container-col">
        <div class="flex-container">
          <ExpandableTableTrafficSources
            :data="data?.deviceFamily"
            :totalClicks="data?.total_clicks"
            :header="'Device Family'"
            :colKey="'deviceFamily'"
          />
        </div>
      </div>
      <div class="grid-container-col">
        <div class="flex-container">
          <ChartDevicesSummary
            class="w-full h-full"
            :data="data?.devices"
            :totalClicks="data?.total_clicks"
          />
        </div>
      </div>
      <div class="grid-container-col">
        <div class="flex-container">
          <ChartBrowsersSummary
            class="h-full"
            :data="data?.browsers"
            :totalClicks="data?.total_clicks"
          />
        </div>
      </div>
      <div class="grid-container-col">
        <div class="flex-container">
          <ChartPlatformSummary
            :data="data?.platforms"
            :totalClicks="data?.total_clicks"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.grid-container {
  @apply grid grid-cols-2 h-full gap-4 overflow-y-auto;
}

.grid-container-col {
  @apply col-span-1 md:col-span-2 lg:col-span-1 border rounded-2xl h-[26rem] overflow-hidden;
}
.flex-container {
  @apply flex flex-wrap h-full w-full;
}
</style>
